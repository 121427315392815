import { template as template_b0d862993fb6416d9213c4e1f51e3349 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b0d862993fb6416d9213c4e1f51e3349(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
