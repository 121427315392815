import { template as template_05e9b9d7a8f64bb0a2405d4ffe531072 } from "@ember/template-compiler";
const FKText = template_05e9b9d7a8f64bb0a2405d4ffe531072(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
