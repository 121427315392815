import { template as template_bbcf91d8c40a420dad36eb4f3f3a7f26 } from "@ember/template-compiler";
const EmptyState = template_bbcf91d8c40a420dad36eb4f3f3a7f26(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
