import { template as template_105efdad339143d29f2dcb50c257b2c5 } from "@ember/template-compiler";
const FKLabel = template_105efdad339143d29f2dcb50c257b2c5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
